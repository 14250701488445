import React, { Component } from "react";
import styled from "styled-components";
import Layout from "../layout/Layout";
import { industrialBuildings } from '../data/industrialBuildings';

const InvestitonsWrapper = styled.div`
  width: 100vw;
  font-size: 14px;

  ${({ theme }) => theme.media.investitionsDesktop} {
    display: flex;
    height: 100%;
    
  }
`;

const InvestitonsContent = styled.div`
    padding-top: 8rem;
    position: relative;
    background: #fafafa;
    padding-bottom: 4rem;

    ${({ theme }) => theme.media.investitionsDesktop} {
      display: block;
      width: 25%;
      min-height: 100%;
    }
`;

const RealizationsContentBasic = styled.div`
  height: fit-content;
  margin-left: 3px;
  border-left: 3px solid ${({ theme }) => theme.colors.red};
`;

const DetailsBasicContainer = styled.div`
  display: block;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1.5rem 1.5rem;

  ${({ theme }) => theme.media.investitionsDesktop} {
    padding: 0 4rem 4rem;
  }
`;

const DetailsBasicHeader = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-bottom: 0;
`;

const DetailsBasicContent = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
`;

const InvestitonsContentDetails = styled.div`
  position: relative;
  background: #fff;
  width: 100%;

  ${({ theme }) => theme.media.investitionsDesktop} {
    display: flex;
    width: 75%;
    padding-top: 11.3rem;
    min-height: 100vh;
  }
`;
const DetailsContainer = styled.div`
  display: block;
  width: 100%;
  padding: 4rem 1.5rem;

  ${({ theme }) => theme.media.investitionsDesktop} {
    padding: 0 10rem;
  }
`;

const DetailsHeader = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  display: flex;
  
  margin-bottom: 0.7rem;
`;

const DetailsHeaderInfo = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  display: flex;
  
  margin-bottom: 0.7rem;
`;

const List = styled.ul`
  list-style-type: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  padding: 0.5rem 2rem;

  li {
    padding: 10px 0;
    font-size: calc(100% + 2px);
  }
`;

const Details = props => (
  <>
    <InvestitonsContentDetails>
      <DetailsContainer>
        <DetailsHeader>
          <DetailsHeaderInfo>Nasze realizacje</DetailsHeaderInfo>
        </DetailsHeader>
        <RealizationsContentBasic>
        <List>
          <li>Remont wraz z przebudową dwóch kamienic przy starówce w Mikołowie</li>
          <li>Naprawy bieżące i konserwacyjne na zewnątrz oraz wewnątrz obiektów budowlanych</li>
          <li>Przebudowy domów jednorodzinnych w celu stworzenia przestrzeni mieszkalnej na miarę potrzeb klienta</li>
        </List>
        </RealizationsContentBasic>
      </DetailsContainer>
    </InvestitonsContentDetails>
  </>
)

class Investitions extends Component {
  state = { activeItem: 0 };

  handleClick = (index) => { this.setState({ activeItem: index }) };

  render() {
    const { activeItem } = this.state;
    return (
      <Layout>
        <InvestitonsWrapper>
          <InvestitonsContent>
            <DetailsBasicContainer>
              <DetailsBasicHeader>
                Remonty oraz renowacje
              </DetailsBasicHeader>
              <DetailsBasicContent>
              Zajmujemy się także remontami obiektów budowlanych, przebudową oraz ich rozbudową. Mamy doświadczenie w robotach prowadzonych w strefie ochrony konserwatorskiej oraz pod nadzorem archeologicznym.
              </DetailsBasicContent>
            </DetailsBasicContainer>
          </InvestitonsContent>
          <Details {...industrialBuildings[activeItem]} />
        </InvestitonsWrapper>
      </Layout>
    );
  }
}

export default Investitions;